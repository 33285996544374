import { tokens } from './tokens';

export const CHAIN_LIST = {
  1: {
    env: ['production'],
    chainId: 1,
    displayName: 'Ethereum Mainnet',
    currency: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    blockExplorerUrls: ['https://etherscan.io/'],
    borrowContractAddress: '',
    erc20Token: [tokens.usdt[1]],
  },
  11155111: {
    env: ['develop', 'local', 'staging'],
    chainId: 11155111,
    displayName: 'Sepolia Testnet',
    currency: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    blockExplorerUrls: ['https://sepolia.etherscan.io/'],
    borrowContractAddress: '',
    erc20Token: [tokens.usdt[11155111]],
  },
};
