export const MAX_INPUT_VALUE = 1e20;
export const MAX_LENGTH_INPUT_VALUE_INTEGER = 12;
export const MAX_LENGTH_INPUT_VALUE_FRACTION = 4;
export const ALLOWED_MAX_SIZE_FILE = 5 * 1024 * 1024;

export const DAPP_WALLET_LIST = [
  {
    name: 'Metamask',
    link: 'https://metamask.app.link',
    // icon: metamaskWallet,
  },
  {
    name: 'Trust',
    link: 'https://link.trustwallet.com/open_url?coin_id=60&url=',
    // icon: trustWallet,
  },
];

export const LOGIN_TYPE = {
  DM2: 'DM2',
  WEB3_AUTH: 'WEB3_AUTH',
  IMMUTABLE: 'IMMUTABLE',
};

export const TYPE_POINT = {
  RM_POINT: 'RM_POINT',
  FREE_POINT: 'FREE_POINT',
};

export const TYPE_TAB = {
  SELL: 'SELL',
  MY_ITEMS: 'MY_ITEMS',
};

export const CARD_STATUS = {
  NEW: 'SELL',
  SALE: 'SALE',
  BUYING: 'BUYING',
  SOLD: 'SOLD',
  RE_SALE: 'RE_SALE',
};

export const PROVIDERS = {
  1: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
  3: `https://ropsten.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
  4: 'https://speedy-nodes-nyc.moralis.io/2cac97baa7c919b144ee567f/eth/rinkeby',
  5: `https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
  42: `https://kovan.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
  56: 'https://bsc-dataseed.binance.org/',
  97: 'https://bsc-testnet.blockpi.network/v1/rpc/public',
  421611: 'https://rinkeby.arbitrum.io/rpc',
  81: 'https://rpc.shibuya.astar.network:8545',
  137: 'https://speedy-nodes-nyc.moralis.io/2cac97baa7c919b144ee567f/polygon/mainnet',
  80001: 'https://speedy-nodes-nyc.moralis.io/2cac97baa7c919b144ee567f/polygon/mumbai',
  43113: 'https://api.avax-test.network/ext/bc/C/rpc',
  43114: 'https://api.avax.network/ext/bc/C/rpc',
};
