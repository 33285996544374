import { types } from 'mobx-state-tree';

import { Model } from '../utils/mobx-model-helper';
import { generateCode } from '../api/auth';
import { chainOptions } from '../app/chain-box';
import { getLocalStorage } from '../utils/storage';

const TYPES = {
  GET_AUTH_CODE: 1,
};

const InitialData = types.model('InitialData').props({
  userName: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  publicAddress: types.maybeNull(types.string),
  nativeBalance: types.maybeNull(types.number),
  userImage: types.maybeNull(types.string),
  ownedCount: types.maybeNull(types.number),
  resaleCount: types.maybeNull(types.number),
  resoldCount: types.maybeNull(types.number),
  sellingCount: types.maybeNull(types.number),
  externalWallet: types.maybeNull(types.boolean),
  balance: types.maybeNull(types.string),
});

const AuthStore = Model.named('AuthStore')
  .props({
    loggedIn: types.boolean,
    currency: types.maybeNull(types.string),
    currentChainId: types.maybeNull(types.number),
    initialData: InitialData,
    isOpenLoginModal: types.boolean,
    isOpenContentLoginModal: types.boolean,
    isOpenLoginImmutableModal: types.boolean,
    isOpenLoginDM2CModal: types.boolean,
  })
  .actions(self => ({
    toggleOpenModalLogin() {
      self.isOpenLoginModal = !!self.isOpenLoginModal;
    },

    toggleOpenContentModalLogin() {
      self.isOpenContentLoginModal = !self.isOpenContentLoginModal;
    },

    toggleOpenImmutableModalLogin() {
      self.isOpenLoginImmutableModal = !self.isOpenLoginImmutableModal;
    },

    toggleOpenDM2CModalLogin() {
      self.isOpenLoginDM2CModal = !self.isOpenLoginDM2CModal;
    },

    setLoggedIn(payload) {
      self.loggedIn = payload;
    },

    setInitialData(data) {
      Object.keys(data).forEach(key => {
        self.initialData[key] = data[key];
      });
    },

    getInitialData() {
      return self.initialData;
    },

    getAuthCode(payload) {
      return self.request({
        type: TYPES.GET_AUTH_CODE,
        api: generateCode,
        payload,
      });
    },
  }));

export { TYPES };
export default AuthStore.create({
  isOpenLoginModal: true,
  isOpenContentLoginModal: true,
  isOpenLoginImmutableModal: false,
  isOpenLoginDM2CModal: false,
  loggedIn: false,
  currency: null,
  currentChainId: getLocalStorage('chain') || chainOptions[0]?.value,
  initialData: {
    nativeBalance: 0,
    // usdToJpy: 0,
    publicAddress: '',
    // ethToJpy: 0,
  },
});
